import AdminLayOut from "../../../components/AdminLayOut";
import { useEffect, useState } from "react";
import axios from "axios";
import { FaUser, FaUsers, FaUsersCog } from "react-icons/fa";
import { FaTruckFast } from "react-icons/fa6";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [homeUsersCount, setHomeUsersCount] = useState(0);
  const [adminUserCount, setAdminUserCount] = useState(0);
  const [sanitationWorkerUserCount, setSanitationWorkerUserCount] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/users`)
      .then((response) => {
        if (response.data) {
          setUsers(response.data);
          setLoading(false);

          //GET THE COUNTS HERE
          const counts = { Admin: 0, "Home User": 0, "Sanitation Worker": 0 };
          const users = response.data;
          users.forEach((user) => {
            if (counts.hasOwnProperty(user.user_type)) {
              counts[user.user_type]++;
            }
          });

          setAdminUserCount(counts.Admin);
          setHomeUsersCount(counts["Home User"]);
          setSanitationWorkerUserCount(counts["Sanitation Worker"]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log(users);

  //filtering logic

  useEffect(() => {
    const filtered = users.filter((user) => {
      const matchesStatus =
        selectedFilter === "All"
          ? true
          : user.registration_status.toLowerCase() ===
            selectedFilter.toLowerCase();

      const matchesSearch = searchQuery
        ? `${user.first_name} ${user.last_name}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        : true;

      return matchesStatus && matchesSearch;
    });
    setFilteredUsers(filtered);
    setCurrentPage(1);
  }, [users, selectedFilter, searchQuery]);

  console.log("Filtered users", filteredUsers);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const CardCounter = ({
    icon,
    userType,
    numberOfUsers,
    selectedFilter,
    setSelectedFilter,
  }) => {
    const isSelected = selectedFilter === userType;

    return (
      <div
        onClick={() => setSelectedFilter(userType)}
        className={`flex w-64 cursor-pointer items-center p-4 border rounded-lg shadow-sm space-x-4 transition-transform duration-300 transform 
          ${
            isSelected
              ? "bg-green-100 scale-110 shadow-lg"
              : "bg-gray-50 hover:scale-105 hover:shadow-md"
          }`}
      >
        <div className="text-4xl text-primary-dark">{icon}</div>
        <div>
          <h2 className="text-lg font-semibold text-gray-800">{userType}</h2>
          <p className="text-sm text-gray-500">{numberOfUsers} users</p>
        </div>
      </div>
    );
  };

  console.log("Selected Filter", selectedFilter);

  return (
    <AdminLayOut>
      <div className="flex-1 p-6">
        {/* Add your Learning content here */}
        <h1 className="text-2xl font-bold">Users</h1>

        <div className="flex py-4 space-x-3.5">
          <CardCounter
            icon={<FaUsersCog />}
            userType="Admin"
            numberOfUsers={adminUserCount}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />

          <CardCounter
            icon={<FaUsers />}
            userType="Home Users"
            numberOfUsers={homeUsersCount}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />

          <CardCounter
            icon={<FaTruckFast />}
            userType="Sanitation Workers"
            numberOfUsers={sanitationWorkerUserCount}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
        </div>

        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Contact
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Gender
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        User Status
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        User Type
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Registration Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {currentUsers.map((user, index) => (
                      <tr key={index}>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-10 w-10">
                              {user.image ? (
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={user.image}
                                  alt=""
                                />
                              ) : (
                                <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                                  <FaUser className="h-6 w-6 text-gray-500" />
                                </div>
                              )}
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-bold text-gray-900">
                                {`${user.first_name} ${user.last_name}`}
                              </div>
                              <div className="text-[12px] font-medium text-gray-500">
                                {user.email}
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {user.contact_no}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {user.gender}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-l p-1 ${
                              user.registration_status === "Approved"
                                ? "bg-green-100 text-green-800"
                                : user.registration_status ===
                                  "Pending Approval"
                                ? "bg-yellow-100 text-yellow-800"
                                : user.registration_status === "Declined"
                                ? "bg-red-100 text-red-800"
                                : "bg-gray-100 text-gray-800"
                            }`}
                          >
                            {user.registration_status}
                          </span>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {user.user_type}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {user.registration_date
                            .replace("T", " ")
                            .replace("Z", " ")
                            .replace(".000", "")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="flex justify-between px-6 py-3 bg-gray-50">
                  <div></div>
                  <div>
                    Page {currentPage} of {totalPages}
                  </div>
                  <div>
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="mr-5 px-4 py-2 text-sm text-white bg-gray-500 rounded hover:bg-gray-600 disabled:bg-gray-300"
                    >
                      Previous
                    </button>
                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className="px-4 py-2 text-sm text-white bg-gray-500 rounded hover:bg-gray-600 disabled:bg-gray-300"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayOut>
  );
};

export default Users;