import Contact from "./pages/contact/Contact";
import About from "./pages/about/About";
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login/Login";
import ForRecyclers from "./pages/forrecyclers/ForRecyclers";
import LearnAtGP from "./pages/learnatgp/LearAtGP";
import SanitationWorkers from "./pages/sanitationworkers/SanitationWorkers";
import Dashboard from "./pages/admin/dashboard/Dashboard";
import Requests from "./pages/admin/requests/Requests";
import Rewards from "./pages/admin/rewards/Rewards";
import Users from "./pages/admin/users/Users";
import Learning from "./pages/admin/learning/Learning";
import Notifications from "./pages/admin/notifications/Notifications";
import Profile from "./pages/admin/profile/Profile";
import Settings from "./pages/admin//settings/Settings";
import ManageRequesterProfile from "./pages/admin/requests/ManageRequesterProfile";
import Reports from "./pages/admin/reports/Reports";
import Support from "./pages/admin/support/CustomerSupport";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/admin-login" element={<Login />} />
        <Route path="/recyclers" element={<ForRecyclers />} />
        <Route path="/learn-at-gp" element={<LearnAtGP />} />
        <Route path="/sanitation-workers" element={<SanitationWorkers />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />

        <Route path="/admin/requests" element={<Requests />} />
        <Route
          path="/admin/requests/:requestId"
          element={<ManageRequesterProfile />}
        />

        <Route path="/admin/rewards" element={<Rewards />} />
        <Route path="/admin/users" element={<Users />} />
        <Route path="/admin/learning" element={<Learning />} />
        <Route path="/admin/notifications" element={<Notifications />} />
        <Route path="/admin/profile" element={<Profile />} />
        <Route path="/admin/support" element={<Support />} />
        <Route path="/admin/settings" element={<Settings />} />
        <Route path="/admin/reports" element={<Reports />} />
      </Routes>
    </BrowserRouter>
  );
}
