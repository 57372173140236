import React from "react";

const MessageRequestModal = ({
  isOpen,
  onClose,
  onConfirm,
  username,
  message,
  setMessage,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <p className="mb-4 text-black">
            Send message to <strong>{username}</strong> ?
          </p>

          <form onSubmit={onConfirm}>
            <textarea
              value={message}
              className="w-full h-24 p-2 border rounded"
              placeholder="Write your message"
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <div className="flex justify-end space-x-4">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={onConfirm}
                className="px-4 py-2 bg-primary-light text-white rounded hover:bg-primary-lightd"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MessageRequestModal;
