// CardComponent.js
import React, { useState } from "react";
import { FaEllipsisH } from "react-icons/fa";

const LearningCardComponent = () => {
  const [showMore, setShowMore] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div className="max-w-md p-4 bg-white rounded-lg shadow-md">
      <div className="mb-2">
        <h2 className="text-lg font-bold text-gray-800">
          Contractors & Consultants
        </h2>
        <p className="text-sm text-gray-500">
          Created: <span className="font-medium">15 Jan 2023</span>
        </p>
      </div>
      <p className="text-gray-700 mb-4">
        Do you want to provide an update on any indirect costs related to the
        grant for September and October, so we can class accordingly? Do you
        want to provide an update on any indir...
        {showMore && (
          <span>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed
            possimus officiis modi culpa iusto cumque nulla quisquam adipisci
            reprehenderit qui, praesentium illum vel id eaque impedit, repellat
            alias, earum sint!
          </span>
        )}
      </p>
      {showMore && (
        <div className="mb-4">
          <div className="flex space-x-2">
            {Array.from({ length: 5 }).map((_, index) => (
              <div
                key={index}
                className="w-16 h-16 bg-gray-300 rounded-lg"
              ></div>
            ))}
          </div>
          <input
            type="file"
            multiple
            className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
      )}
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={toggleShowMore}
          className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg"
        >
          {showMore ? "Collapse" : "Expand"}
        </button>
        <button
          onClick={toggleOptions}
          className="p-2 bg-orange-500 text-white rounded-full hover:bg-orange-600"
        >
          <FaEllipsisH />

          {showOptions && (
            <div className="absolute ml-5 bg-white border border-gray-300 rounded-lg shadow-lg">
              <button className="block w-full text-primary-dark text-left px-4 py-2 hover:bg-gray-100">
                Update
              </button>
              <button className="block w-full text-red-500 text-left px-4 py-2 hover:bg-gray-100">
                Delete
              </button>
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default LearningCardComponent;
