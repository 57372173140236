import React from "react";
import imageUrl from "../../img/bicycle-4300053_1920.jpg";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <div
      className="relative bg-center bg-cover bg-no-repeat text-center h-screen flex items-center justify-center px-5 sm:px-8 md:px-12 lg:px-24"
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 text-left ">
        <motion.h2
          className="text-3xl sm:text-xl md:text-5xl font-semibold text-white"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Empowering <br />
          <span className="text-primary-light">
            Green Communities for a Cleaner Tomorrow
          </span>
        </motion.h2>
        <motion.p
          className="mt-8 text-[13px] text-white sm:text-sm md:text-base lg:text-lg w-full sm:w-1/2"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.3 }}
        >
          GreenPickr is a digital platform designed to transform waste
          management by connecting households, businesses, and informal waste
          pickers—our Green Champions—in an effort to build sustainable
          communities.
        </motion.p>
        <motion.div
          className="mt-5 md:mt-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.6 }}
        >
          <a
            href="/booking"
            className="inline-block px-6 py-3 bg-primary-light text-white rounded-lg hover:bg-primary-lightd"
          >
            Explore Our Services
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
