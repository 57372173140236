import React, { useState } from 'react';
import AdminNavigation from "../../../components/AdminNavigation";
import AdminSideBar from "../../../components/AdminSideBar";
import NotificationCard from './NotificationCard';

const Notifications = () => {
  const [filter, setFilter] = useState("all");
  
  // Mock notification data
  const mockNotifications = [
    {
      id: 1,
      title: "System Update Available",
      message: "A new version (v2.5.1) is ready to install. Update now for improved performance and security fixes.",
      read: false,
      date: "10 minutes ago",
      icon: "🔄"
    },
    {
      id: 2,
      title: "New Order Received",
      message: "Order #ORD-2871 for $245.00 from John Doe has been placed.",
      read: true,
      date: "2 hours ago",
      icon: "🛒"
    },
    {
      id: 3,
      title: "Payment Processed",
      message: "Payment of $245.00 for order #ORD-2871 has been successfully processed.",
      read: false,
      date: "3 hours ago",
      icon: "💳"
    },
    {
      id: 4,
      title: "Account Security Alert",
      message: "Your password will expire in 7 days. Please update it to maintain account security.",
      read: true,
      date: "1 day ago",
      icon: "🔒"
    },
    {
      id: 5,
      title: "New Message",
      message: "You have 3 unread messages from customers waiting for your response.",
      read: false,
      date: "2 days ago",
      icon: "✉️"
    },
  ];

  const [notifications, setNotifications] = useState(mockNotifications);

  const markAsRead = (id) => {
    setNotifications(notifications.map(notification =>
      notification.id === id ? { ...notification, read: true } : notification
    ));
  };

  const markAllAsRead = () => {
    setNotifications(notifications.map(notification =>
      ({ ...notification, read: true })
    ));
  };

  // First filter notifications based on the selected filter
  const filteredNotifications = notifications.filter(notification => {
    if (filter === "read") return notification.read;
    if (filter === "unread") return !notification.read;
    return true; // 'all' filter
  });

  // Then sort the filtered notifications with unread first
  const sortedNotifications = [...filteredNotifications].sort((a, b) => {
    // Sort by read status first (unread notifications on top)
    if (a.read !== b.read) {
      return a.read ? 1 : -1;
    }
    
    // If read status is the same, sort by date (assuming newer dates come first)
    // This is a simple string comparison since the dates are in text format
    // For a more accurate sorting, you would need to parse these date strings
    return a.date > b.date ? -1 : 1;
  });

  return (
    <div className="flex w-full h-screen bg-gray-50">
      <AdminSideBar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <AdminNavigation />
        <div className="flex-1 p-6 overflow-y-auto">
          <div className="w-full px-4 md:px-8 lg:px-16">
            <div className="flex justify-between items-center mb-8">
              <h1 className="text-3xl font-bold text-gray-800">Notifications</h1>
              <button
                onClick={markAllAsRead}
                className="px-4 py-2 bg-primary-light text-white rounded-lg hover:bg-primary-light transition-colors flex items-center gap-2"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
                Mark All as Read
              </button>
            </div>

            {/* Filter tabs */}
            <div className="flex gap-2 mb-8">
              <button
                className={`px-4 py-2 rounded-full font-medium transition-colors ${filter === "all" ? "bg-indigo-100 text-indigo-700" : "text-gray-500 hover:bg-gray-100"}`}
                onClick={() => setFilter("all")}
              >
                All
              </button>
              <button
                className={`px-4 py-2 rounded-full font-medium transition-colors ${filter === "unread" ? "bg-indigo-100 text-indigo-700" : "text-gray-500 hover:bg-gray-100"}`}
                onClick={() => setFilter("unread")}
              >
                Unread
              </button>
              <button
                className={`px-4 py-2 rounded-full font-medium transition-colors ${filter === "read" ? "bg-indigo-100 text-indigo-700" : "text-gray-500 hover:bg-gray-100"}`}
                onClick={() => setFilter("read")}
              >
                Read
              </button>
            </div>

            {/* Notifications list */}
            <div className="space-y-4">
              {sortedNotifications.length === 0 ? (
                <div className="text-center py-12">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                  </svg>
                  <h3 className="mt-4 text-lg font-medium text-gray-700">No notifications found</h3>
                  <p className="mt-1 text-gray-500">You're all caught up!</p>
                </div>
              ) : (
                sortedNotifications.map((notification) => (
                  <NotificationCard 
                    key={notification.id}
                    id={notification.id}
                    title={notification.title}
                    message={notification.message}
                    read={notification.read}
                    date={notification.date}
                    icon={notification.icon}
                    markAsRead={markAsRead}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;