import React, { useState } from 'react';
import AdminNavigation from "../../../components/AdminNavigation";
import AdminSideBar from "../../../components/AdminSideBar";

const Settings = () => {
  // Load settings from localStorage or use defaults
  const loadSettings = () => {
    const savedSettings = localStorage.getItem('appSettings');
    return savedSettings ? JSON.parse(savedSettings) : {
      darkMode: false,
      notifications: true,
      language: 'en',
      timezone: 'UTC',
    };
  };

  const [settings, setSettings] = useState(loadSettings());
  const [status, setStatus] = useState({ message: '', type: '' });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      // Save to localStorage (simulating API call)
      localStorage.setItem('appSettings', JSON.stringify(settings));
      
      // Show success message
      setStatus({ message: 'Settings saved successfully!', type: 'success' });
      
      // Hide message after 3 seconds
      setTimeout(() => setStatus({ message: '', type: '' }), 3000);
      
      // Apply dark mode immediately if changed
      if (settings.darkMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    } catch (error) {
      setStatus({ message: 'Failed to save settings', type: 'error' });
      setTimeout(() => setStatus({ message: '', type: '' }), 3000);
    }
  };

  return (
    <div className="flex w-full h-screen bg-gray-50">
      <AdminSideBar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <AdminNavigation />
        <div className="flex-1 p-6 overflow-y-auto">
          <div className="max-w-3xl mx-auto">
            <div className="mb-8">
              <h1 className="text-2xl font-bold text-gray-800">Settings</h1>
              <p className="text-gray-500">Manage your application preferences</p>
            </div>

            {/* Status Message */}
            {status.message && (
              <div className={`mb-4 p-3 rounded-md ${
                status.type === 'success' 
                  ? 'bg-green-100 text-green-800' 
                  : 'bg-red-100 text-red-800'
              }`}>
                {status.message}
              </div>
            )}

            <form onSubmit={handleSubmit}>
              <div className="bg-white rounded-lg shadow-sm p-6">
                <div className="space-y-6">
                  {/* Appearance */}
                  <div>
                    <h2 className="text-lg font-semibold text-gray-800 mb-4">Appearance</h2>
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-sm font-medium text-gray-700">Dark Mode</p>
                        <p className="text-sm text-gray-500">Switch between light and dark theme</p>
                      </div>
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          name="darkMode"
                          checked={settings.darkMode}
                          onChange={handleChange}
                          className="sr-only peer"
                        />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:text-primary-dark"></div>
                      </label>
                    </div>
                  </div>

                  {/* Notifications */}
                  <div>
                    <h2 className="text-lg font-semibold text-gray-800 mb-4">Notifications</h2>
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-sm font-medium text-gray-700">Enable Notifications</p>
                        <p className="text-sm text-gray-500">Receive system notifications</p>
                      </div>
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          name="notifications"
                          checked={settings.notifications}
                          onChange={handleChange}
                          className="sr-only peer"
                        />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:text-primary-dark"></div>
                      </label>
                    </div>
                  </div>

                  {/* Language */}
                  <div>
                    <h2 className="text-lg font-semibold text-gray-800 mb-4">Language</h2>
                    <select
                      name="language"
                      value={settings.language}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                      <option value="en">English</option>
                      <option value="es">Spanish</option>
                      <option value="fr">French</option>
                      <option value="de">German</option>
                    </select>
                  </div>

                  {/* Timezone */}
                  <div>
                    <h2 className="text-lg font-semibold text-gray-800 mb-4">Timezone</h2>
                    <select
                      name="timezone"
                      value={settings.timezone}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                      <option value="UTC">UTC</option>
                      <option value="EST">Eastern Time (EST)</option>
                      <option value="PST">Pacific Time (PST)</option>
                      <option value="CET">Central European Time (CET)</option>
                    </select>
                  </div>

                  {/* Save Button */}
                  <div className="pt-4">
                    <button 
                      type="submit"
                      className="px-4 py-2 bg-primary-light text-white rounded-md hover:bg-primary-light transition-colors"
                    >
                      Save Preferences
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;