import React, { useState } from 'react';
import AdminNavigation from "../../../components/AdminNavigation";
import AdminSideBar from "../../../components/AdminSideBar";

// Mock support queries data
const mockQueries = [
  {
    id: 1,
    title: "Login issues",
    description: "Unable to login to my account despite resetting password twice",
    date: "2023-06-15",
    status: "Open",
    user: {
      name: "John",
      surname: "Doe",
      email: "john.doe@example.com"
    },
    supportUser: "Sarah Johnson"
  },
  {
    id: 2,
    title: "Payment failed",
    description: "Payment gateway showing error when trying to complete purchase",
    date: "2023-06-14",
    status: "In Progress",
    user: {
      name: "Jane",
      surname: "Smith",
      email: "jane.smith@example.com"
    },
    supportUser: "Michael Brown"
  },
  {
    id: 3,
    title: "Feature request",
    description: "Would like to request dark mode for the mobile application",
    date: "2023-06-13",
    status: "Completed",
    user: {
      name: "Robert",
      surname: "Johnson",
      email: "robert.j@example.com"
    },
    supportUser: "Lisa Wang"
  }
];

const CustomerSupport = () => {
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredQueries = mockQueries.filter(query =>
    query.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    query.user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    query.user.surname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const openModal = (query) => {
    setSelectedQuery(query);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedQuery(null);
  };

  return (
    <div className="flex w-full h-screen bg-gray-50">
      <AdminSideBar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <AdminNavigation />
        <div className="flex-1 p-6 overflow-y-auto">
          <div className="max-w-6xl mx-auto">
            <div className="mb-8">
              <h1 className="text-2xl font-bold text-gray-800">Customer Support Queries</h1>
              <p className="text-gray-500">Manage and respond to customer issues</p>
            </div>

            {/* Search bar */}
            <div className="mb-6">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search queries..."
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <svg
                  className="absolute right-3 top-3 h-5 w-5 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
            </div>

            {/* Queries table */}
            <div className="bg-white rounded-lg shadow overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Support Agent</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredQueries.map((query) => (
                    <tr 
                      key={query.id} 
                      className="hover:bg-gray-50 cursor-pointer"
                      onClick={() => openModal(query)}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{query.id}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{query.title}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {query.user.name} {query.user.surname}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{query.supportUser}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{query.date}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          query.status === "Open" ? "bg-red-100 text-red-800" :
                          query.status === "In Progress" ? "bg-yellow-100 text-yellow-800" :
                          "bg-green-100 text-green-800"
                        }`}>
                          {query.status}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Query Detail Modal */}
            {isModalOpen && selectedQuery && (
              <div className="fixed inset-0 z-50 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={closeModal}></div>
                  </div>
                  <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                          <div className="flex justify-between items-start">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              Query #{selectedQuery.id}: {selectedQuery.title}
                            </h3>
                            <button
                              onClick={closeModal}
                              className="text-gray-400 hover:text-gray-500"
                            >
                              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                          <div className="mt-4 space-y-4">
                            <div>
                              <h4 className="text-sm font-medium text-gray-500">Description</h4>
                              <p className="mt-1 text-sm text-gray-900">{selectedQuery.description}</p>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <div>
                                <h4 className="text-sm font-medium text-gray-500">User</h4>
                                <p className="mt-1 text-sm text-gray-900">
                                  {selectedQuery.user.name} {selectedQuery.user.surname}
                                </p>
                              </div>
                              <div>
                                <h4 className="text-sm font-medium text-gray-500">Email</h4>
                                <p className="mt-1 text-sm text-gray-900">{selectedQuery.user.email}</p>
                              </div>
                              <div>
                                <h4 className="text-sm font-medium text-gray-500">Date Reported</h4>
                                <p className="mt-1 text-sm text-gray-900">{selectedQuery.date}</p>
                              </div>
                              <div>
                                <h4 className="text-sm font-medium text-gray-500">Status</h4>
                                <p className="mt-1 text-sm text-gray-900">
                                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                    selectedQuery.status === "Open" ? "bg-red-100 text-red-800" :
                                    selectedQuery.status === "In Progress" ? "bg-yellow-100 text-yellow-800" :
                                    "bg-green-100 text-green-800"
                                  }`}>
                                    {selectedQuery.status}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div>
                              <h4 className="text-sm font-medium text-gray-500">Assigned Support Agent</h4>
                              <p className="mt-1 text-sm text-gray-900">{selectedQuery.supportUser}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-light text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSupport;