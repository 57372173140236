// LearningModal.jsx
import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";

const LearningModal = ({ isOpen, onClose, onSubmit }) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [images, setImages] = useState([]);
  const [youTubeLinks, setYouTubeLinks] = useState([""]);

  const handleYouTubeLinkChange = (index, value) => {
    const newLinks = [...youTubeLinks];
    newLinks[index] = value;
    setYouTubeLinks(newLinks);
  };

  const addYouTubeLink = () => {
    setYouTubeLinks([...youTubeLinks, ""]);
  };

  const deleteYouTubeLink = (index) => {
    setYouTubeLinks(youTubeLinks.filter((_, i) => i !== index));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
    onSubmit({ title, content, images, youTubeLinks });
    onClose(); // Close the modal after submitting
  };

  return (
    isOpen && (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
        <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3 p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Create New Learning Item</h2>
            <button
              className="text-gray-600 hover:text-gray-900"
              onClick={onClose}
            >
              &times;
            </button>
          </div>
          <form onSubmit={handleFormSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700">Title</label>
              <input
                className="appearance-none border rounded-lg w-full py-3 px-3 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Content</label>
              <textarea
                className="appearance-none border rounded-lg w-full py-3 px-3 leading-tight focus:outline-none focus:shadow-outline"
                rows="5"
                placeholder="Content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                required
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Upload Images</label>
              <input
                type="file"
                multiple
                className="appearance-none border rounded-lg w-full py-3 px-3 leading-tight focus:outline-none focus:shadow-outline"
                onChange={(e) => setImages([...e.target.files])}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">YouTube Links</label>
              {youTubeLinks.map((link, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    className="appearance-none border rounded-lg w-full py-3 px-3 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="YouTube Link"
                    value={link}
                    onChange={(e) =>
                      handleYouTubeLinkChange(index, e.target.value)
                    }
                  />
                  <button
                    type="button"
                    className="ml-2 p-2 text-red-500 hover:text-red-700"
                    onClick={() => deleteYouTubeLink(index)}
                  >
                    <FaTimes />
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="text-blue-500 hover:text-blue-700"
                onClick={addYouTubeLink}
              >
                + Add another link
              </button>
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-primary-light text-white px-4 py-2 rounded-lg hover:bg-primary-lightd focus:outline-none focus:shadow-outline"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default LearningModal;
