import React, { useEffect, useState } from "react";
import NavigationBar from "../home/NavigationBar";
import Footer from "../home/Footer";
import axios from "axios";
import LearningContent from "./LearningContent";

const LearnAtGP = () => {
  const [learningAndTrainingContent, setLearningAndTrainingContent] = useState(
    []
  );
  // Only animate once when in view
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_LEARNING_AND_TRAINING}`)
      .then((res) => {
        if (res.data) {
          setLearningAndTrainingContent(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log("content", learningAndTrainingContent);
  return (
    <div>
      <NavigationBar />
      <h2 className="text-5xl pt-20 font-normal text-center">
        There's a lot to learn at GreenPickr 📖
      </h2>
      <div className="pt-4">
        {learningAndTrainingContent.map((resource, index) => (
          <LearningContent
            key={resource.resource_id}
            resources={resource} // Pass each resource to LearningContent
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default LearnAtGP;
