import React from 'react'

function NotificationCard({
    id,
    title,
    message,
    read,
    date,
    icon,
    markAsRead
}) {
    return (
        <div
            key={id}
            className={`p-5 rounded-xl transition-all bg-white border-l-4 duration-200 ${read? "text-primary-dark shadow-sm":" border-green-500 text-primary-dark shadow-sm"

                }`}
        >
            <div className="flex gap-4">
                <div
                    className={`flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${!read ? "bg-indigo-100 text-primary-dark" : "bg-gray-200 text-gray-600"
                        }`}
                >
                    <span className="text-xl">{icon}</span>
                </div>
                <div className="flex-1 min-w-0">
                    <div className="flex justify-between items-start">
                        <h3
                            className={`text-lg font-medium ${!read ? "text-gray-900" : "text-gray-700"
                                }`}
                        >
                            {title}
                        </h3>
                        <span className="text-sm text-gray-400 whitespace-nowrap ml-2">
                            {date}
                        </span>
                    </div>
                    <p className="mt-1 text-gray-600">{message}</p>
                    {!read && (
                        <button
                            onClick={() => markAsRead(id)}
                            className="mt-3 inline-flex items-center text-sm font-medium text-indigo-600 hover:text-indigo-800 transition-colors"
                        >
                            Mark as read
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 ml-1"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default NotificationCard
