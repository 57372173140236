// RequestersRadius.js
import React from "react";
import { GoogleMap, LoadScript, Circle } from "@react-google-maps/api";

const mapContainerStyle = {
  height: "600px",
  width: "100%",
};

const radiusOptions = {
  strokeColor: "#57cc99",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#57cc99",
  fillOpacity: 0.35,
  radius: 3000, // radius in meters, example 3km
};

const RequestersRadius = ({ center }) => {
  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_APIKEY}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={13}
        center={center}
      >
        <Circle center={center} options={radiusOptions} />
      </GoogleMap>
    </LoadScript>
  );
};

export default RequestersRadius;
