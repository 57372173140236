import axios from "axios";
import { useState, useEffect } from "react";
import { FaTrash, FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function RequestsTable({
  statusFilter,
  setStatusFilter,
  searchQuery,
  setSearchQuery,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const [requests, setRequests] = useState([]);
  const [filteredPeople, setFilteredPeople] = useState([]);
  const navigate = useNavigate();

  // Fetching requests from API
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SANITATION_WORKER_REQUESTS}`)
      .then((res) => {
        if (res.data) {
          console.log("All sanitation requests", res.data);
          setRequests(res.data);
        }
      });
  }, []);

  // Filtering logic
  useEffect(() => {
    const filtered = requests.filter((request) => {
      const matchesStatus =
        statusFilter === "All"
          ? true
          : request.status.toLowerCase() === statusFilter.toLowerCase();

      const matchesSearch = searchQuery
        ? `${request.user.first_name} ${request.user.last_name}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        : true;

      return matchesStatus && matchesSearch;
    });

    setFilteredPeople(filtered);
    setCurrentPage(1);
  }, [requests, statusFilter, searchQuery]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPeople.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredPeople.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Navigate to a single request view
  const handleManageRequest = (request) => {
    navigate(`/admin/requests/${request.request_no}`, { state: { request } });
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Request Number
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date and Time
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Location
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentItems.map((request) => (
                  <tr key={request.request_id}>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          {request.image ? (
                            <img
                              className="h-10 w-10 rounded-full"
                              src={request.image}
                              alt=""
                            />
                          ) : (
                            <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                              <FaUser className="h-6 w-6 text-gray-500" />
                            </div>
                          )}
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-bold text-gray-900">
                            {`${request.user.first_name} ${request.user.last_name}`}
                          </div>
                          <div className="text-[12px] font-medium text-gray-500">
                            {request.user.email}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div
                        onClick={() => handleManageRequest(request)}
                        className="text-sm font-bold underline text-primary-dark cursor-pointer"
                      >
                        {request.request_no}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-l p-1 ${
                          request.status === "Approved"
                            ? "bg-green-100 text-green-800"
                            : request.status === "Pending Approval"
                            ? "bg-yellow-100 text-yellow-800"
                            : request.status === "Declined"
                            ? "bg-red-100 text-red-800"
                            : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        {request.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {request.user.registration_date
                        .replace("T", " ")
                        .replace("Z", " ")
                        .replace(".000", "")}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {request.locations.length <= 0
                        ? "Waiting profile Completion"
                        : request.locations[0].address}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="flex justify-end space-x-2">
                        <button
                          onClick={() => handleManageRequest(request)}
                          className="px-4 py-2 text-sm text-white bg-primary-lightd rounded hover:bg-primary-dark"
                        >
                          Manage Request
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-between px-6 py-3 bg-gray-50">
              <div></div>
              <div>
                Page {currentPage} of {totalPages}
              </div>
              <div>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="mr-5 px-4 py-2 text-sm text-white bg-gray-500 rounded hover:bg-gray-600 disabled:bg-gray-300"
                >
                  Previous
                </button>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="px-4 py-2 text-sm text-white bg-gray-500 rounded hover:bg-gray-600 disabled:bg-gray-300"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
